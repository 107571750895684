import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService, AlertService, StudentDataService, FileUploadService,MasterDataService } from '@app/_services';
import { FormsModule  } from '@angular/forms';
import { GlobalProvider } from '../../providers/global/global';
import { environment } from '@environments/environment';
import { User,StuOnlineTransact } from '@app/_models';
import { WindowRefService } from '../window-ref.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-admission',
  templateUrl: './admission.component.html',
  styleUrls: ['./admission.component.css']
})
export class AdmissionComponent implements OnInit {
  public IsFillPayInfo  : boolean =false;
  public IsAbleToSavePayForm : boolean =false;
  public isApplDisable  : boolean =true;
  public stuadmform: any = {};
  public stuadmfees: any = {};
  public courseApplied: any = {};
  
  public IsConfirmApplication : boolean =false;
  public IAccept : boolean =false;

  public ReadonlyTXNAmount : boolean =false;
  options = [
    {
      text: "Full Payment",
      value: 'full'
    },
    {
      text: "Payment by Installment",
      value: 'min'
    }
  ]

//public payform : paymentform ;
  StudentExamInfo :[];
  StudentAdmSubjects :  [];
  StudentAdmDocsReq :[];
  StudentAdmFreeshipReq : []
  public StudentDeclaration: DeclareDetail;
  courseapplied : any;
  totalfees= 0;
  minfees= [];

  sot: StuOnlineTransact = new StuOnlineTransact();

  feestopay= 0;
  minfeeselcnt = 0;

///// payment fields 

//  Payment Gateway
  // feepaymode = '';  //  Total
  // seltotalfee = false;
  
  // payment_gateway_name = 'WorldLine';

  // payment_method_type = 'paygate'; //  'alreadypaid';   //  ''
  // payment_gateway_enabled = true;

  // show_fees = true;
  // feesbeforetax = 0;
  // gateway_charges = 0;  //  0.0236;   //  get from server


  //  Without Payment Gateway
  feepaymode = '';  //  Total
  seltotalfee = false;
  
  payment_gateway_name = '';

  payment_method_type = 'alreadypaid';   //  ''
  payment_gateway_enabled = false;

  show_fees = true;
  feesbeforetax = 0;
  gateway_charges = 0;  //  0.0236;   //  get from server

  accept_fees = true;
  //  if accept_fees is TRUE then paymentsuccessful should be FALSE
  paymentsuccessful = !this.accept_fees;

  ShowResult = false;
  pendingfeestopay = null;
  fees_balance_message = '';
  errorslist = [];
  errormessage = '';

  resultstatus = '';
  currentstage = 'Checking';
  newcoursename = '';
  newclassname = '';
  allowadm = 'Yes';

  constructor(private studentDataService : StudentDataService, private mastreDataService :MasterDataService,
    private http: HttpClient, 
    private gp : GlobalProvider, private winRef: WindowRefService) { }

  ngOnInit() {
    this.CheckEligibility();
  }

  /* validatePaymentForm(){
    this.IsAbleToSavePayForm = true;
  debugger;
  if(this.payform.paymenttype == null || this.payform.paymenttype == '')
    {
      this.IsAbleToSavePayForm = false;
     
    }
    if(this.payform.txnamount == null || this.payform.txnamount==0)
    {
      this.IsAbleToSavePayForm = false;
     
    }
    if(this.payform.txndate == null || this.payform.txndate=='')
    {
      this.IsAbleToSavePayForm = false;
    }
    if(this.payform.paymentmode == null || this.payform.paymentmode=='')
    {
      this.IsAbleToSavePayForm = false;
    }
    if(this.payform.paymentby == null || this.payform.paymentby=='')
    {
      this.IsAbleToSavePayForm = false;
    }
    if(this.payform.transactionno == null || this.payform.transactionno=='')
    {
      this.IsAbleToSavePayForm = false;
    }
    if(this.payform.txnamount != null && this.payform.txnamount != 0 && this.payform.txnamount < this.payform.mintopay )
    {
      this.IsAbleToSavePayForm = false;
    }
    if(this.payform.txnamount != null && this.payform.txnamount != 0 && this.payform.txnamount > this.payform.totalfees )
    {
      this.IsAbleToSavePayForm = false;
    }
  }
  GetPayment(value){
    if(value == 'full'){
      this.payform.txnamount =  this.payform.totalfees
      this.ReadonlyTXNAmount = true;
    }
    if(value == 'min'){
      this.payform.txnamount =  this.payform.mintopay
      this.ReadonlyTXNAmount = false;
    }
  } */
 /*  SavePaymentForm(){
    this.IsAbleToSavePayForm = false;
    this.studentDataService.SavePaymentForm(this.payform)
    .subscribe((a: any) => {
       
        if (a.status === 'Success') {
          this.stuadmform = a.res;
          this.payform = a.sot;
          this.IsFillPayInfo = a.IsFillPayInfo;
          window.alert(a.message)
        }
        if (a.status === 'Error') {
         window.alert(a.errormessage)
        }
    });
  } */
  
  CheckEligibility() {
    // this.GetPendingFeesDetails();
    this.studentDataService.CheckCurrentAdmissionStatus().subscribe((a: any) => {
      if (a.status === "Success") {
        this.currentstage = a.stage;
        this.resultstatus = a.resultstatus;
        this.errorslist = a.errorslist;
        this.allowadm = a.allowadm;

        if (a.errormessage !== undefined && a.errormessage !== null) {
          this.errormessage = a.errormessage;
        }

        if (a.coursename !== undefined) {
          this.newcoursename = a.coursename;
          this.newclassname = a.classname;
        }

        if (a.fees_balance_message !== undefined) {
            this.fees_balance_message = a.fees_balance_message;
        }

        if (a.stage === 'Applied') {
          this.stuadmform = a.res;
          this.currentstage = a.stage;

          if(a.res.stuadmissionformid != undefined && a.res.stuadmissionformid != 0){
            this.StudentDeclaration = new DeclareDetail();

            this.GetStudentAdmSubjects(a.res.stuadmissionformid);
            this.GetStudentAdmDocsReq(a.res.stuadmissionformid);
            this.GetStudentAdmFreeshipReq(a.res.stuadmissionformid);
            this.GetStudentExamInfo(a.res.stuadmissionformid);

            this.GetStudentDeclaration();
            this.GetParentDeclaration();
            this.GetAntiRaggingDeclaration();
          }
        }

        if (a.stage === 'Submitted') {
          if (a.res !== undefined && a.res !== null) {
            this.stuadmform = a.res;
          }
          this.totalfees = a.totalfees;

          this.minfees = a.feeinstallments;
          this.sot.t_id = 0;
        }

        if (a.stage === 'Challan Generated') {

        }
      }
      if (a.status === "Error") {
        window.alert(a.errormessage);
      }
    });
  }

  GetStudentFeeData() {
    this.studentDataService.GetStudentFeeData(this.stuadmform.cc_name,this.courseApplied.classno)
   /*  this.http.post(this.gp.basedataurl + 'StudAdmInfo/GetStudentFeesApplicable', {
      sessionid: this.gp.sessionid,
      cc_name: this.StudModel.cc_name
    }) */
    .subscribe((data: any) => {
        console.log(data);
        if (data !== undefined && data !== null) {
          if (data.status === "Success") {
            this.totalfees = data.totalfees;

            this.minfees = data.feeinstallments;
            // this.feestopay = data.totalfees;
          }
        }
    }, 
    error => {
      alert("Check internet connection !!");
    });
  }

  GetPendingFeesDetails() {
    this.ShowResult = false;
    this.feestopay = null;

    this.studentDataService.GetPendingFeesDetails('ALL').subscribe((a: any) => {
      if (a.status === "Success") {
        this.feestopay = a.res;
        this.fees_balance_message = a.fees_balance_message;
        if (this.feestopay !== null && this.feestopay === 0) {
          this.GetStudentAdmForm();
          this.StudentDeclaration = new DeclareDetail();
           //this.payform = new paymentform();
          this.GetStudentDeclaration();
          this.GetParentDeclaration();
          this.GetAntiRaggingDeclaration();

          this.ShowResult = true;
        }
      }
      if (a.status === "Error") {
        window.alert(a.errormessage);
      }
    });
  }

  ApplyForAdmission() {
    this.studentDataService.GenerateAdmissionForm(this.newclassname)
      .subscribe((a: any) => {
        if (a !== null && a.status === 'Success') {
          this.stuadmform = a.res;
          this.currentstage = a.stage;

          if(a.res.stuadmissionformid != undefined && a.res.stuadmissionformid != 0){
            this.StudentDeclaration = new DeclareDetail();

            this.GetStudentExamInfo(a.res.stuadmissionformid);
            this.GetStudentAdmSubjects(a.res.stuadmissionformid);
            this.GetStudentAdmDocsReq(a.res.stuadmissionformid);
            this.GetStudentAdmFreeshipReq(a.res.stuadmissionformid);

            this.GetStudentDeclaration();
            this.GetParentDeclaration();
            this.GetAntiRaggingDeclaration();

            this.isApplDisable = true;
          }
        }
      }, 
      error => {
        alert(error.error);
      });
  }

  ConfirmAdmission() {
    this.studentDataService.ConfirmAdmissionForm(this.stuadmform)
    .subscribe((a: any) => {
      if (a !== null && a.status === 'Success') {
        this.stuadmform = a.res;
        this.currentstage = a.stage;
        this.totalfees = a.totalfees;

        this.minfees = a.feeinstallments;
        this.sot.t_id = 0;
        alert('Saved successfully...!');
      }
    }, 
    error => {
      alert(error.error);
    });
  }

  private GetStudentAdmForm() {
    
    this.studentDataService.getStudentAdmFormInfo()
    .subscribe((a: any) => {
       
        if (a.status === 'Success') {
            console.log("DAta");
            console.log(a);
         
            this.stuadmform = a.res;
            this.stuadmfees = a.sfee;
            this.courseApplied = a.courseApplied;
         
            this.IsConfirmApplication = a.IsConfirmApplication;
            //this.payform.mintopay = a.MinFeesTOPay;
            if(a.res.stuadmissionformid != undefined && a.res.stuadmissionformid != 0){
              this.GetStudentExamInfo(a.res.stuadmissionformid);
              this.GetStudentAdmSubjects(a.res.stuadmissionformid);
             this.GetStudentAdmDocsReq(a.res.stuadmissionformid);
             this.GetStudentAdmFreeshipReq(a.res.stuadmissionformid);
            // this.payform.mintopay = a.MinFeesTOPay;
            //this.payform.stuadmissionformid = a.res.stuadmissionformid;

            //this.payform.totalfees = a.sfee.totalfees;

            this.GetStudentFeeData();
             this.minfees = a.feeinstallments;
             this.accept_fees = true;
                    this.paymentsuccessful = true;
            }
            
         this.IsFillPayInfo = a.IsFillPayInfo;
            //this.validatePaymentForm();
          
        }
    });
}
private GetStudentAdmSubjects(formid) {
    
  this.studentDataService.getStudentAdmSubjects(formid)
  .subscribe((a: any) => {
     
      if (a.status === 'Success') {
          console.log("DAta");
          console.log(a);
          this.StudentAdmSubjects = a.subjects;  
        }
  });
}
private GetStudentAdmDocsReq(formid) {
    
  this.studentDataService.getStudentAdmDocsReq(formid)
  .subscribe((a: any) => {
     
      if (a.status === 'Success') {
          console.log("DAta");
          console.log(a);
          this.StudentAdmDocsReq = a.DocList;
         
      }
  });
}
numberOnly(event): boolean {
 // this.validatePaymentForm();
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;

}
private GetStudentExamInfo(formid) {
    
  this.studentDataService.getStudentExamInfo(formid)
  .subscribe((a: any) => {
     
      if (a.status === 'Success') {
          console.log("DAta");
          console.log(a);
          this.StudentExamInfo = a.StuEduDetailList;
         
      }
  });
}

private GetStudentAdmFreeshipReq(formid) {
    
  this.studentDataService.getStudentAdmFreeshipReq(formid)
  .subscribe((a: any) => {
     
      if (a.status === 'Success') {
          console.log("DAta");
          console.log(a);
          this.StudentExamInfo = a.StuEduDetailList;
         
      }
  });
}


change_payment_method() {
  //  alert(this.payment_method_type);
  this.sot.paymentmode = this.payment_method_type;
}

public createPGOrderOld() {
  console.log('Create PG Order');

  
  this.studentDataService.CreatePGOrder(this.feestopay.toString())
  .subscribe((data: any) => {
      console.log(data);
      if (data.status === "Success") {
          if (this.payment_gateway_name === 'WorldLine') {
            var form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", data.url);    // "https://cgt.in.worldline.com/ipg/doMEPayRequest");
            form.setAttribute("target", "_self");
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("name", "merchantRequest");
            //hiddenField.setAttribute("value","3121044C3E93F5C15F2DC2F9991473AFA1539A3B05964ACFC16D72B65B10846CE3CF7908BA9CE85F18D3A1AABCB494DA23B7D9DAB07FD9559F5B57DA807D0E4E798017B3F4FA9EFED4C6B28C34F6662D719C41C650F9397B92CE8E5ED3DD38486D4F48BA66FAFCC24A6B8CD812E871C8B5AB29421E86C684F7BE6B2DAE5F7953" );
            hiddenField.setAttribute("value",data.res);
            hiddenField.setAttribute("type", "hidden");
            form.appendChild(hiddenField);

            var hiddenField2 = document.createElement("input");
            hiddenField2.setAttribute("name", "MID");
            // hiddenField2.setAttribute("value","WL0000000027698" );
            hiddenField2.setAttribute("value",data.mid);
            hiddenField2.setAttribute("type", "hidden");
            form.appendChild(hiddenField2);

            document.body.appendChild(form);
            form.submit();

          }
          else if (this.payment_gateway_name === 'RazorPay') {
            // call api to create order_id
            this.payWithRazor(data.res);
          }
      }
      else
      {
        alert("Required data of:\r\n" + data.errormessage);
      }
  }, 
  error => {
    alert("Check internet connection !!");
  });
}

public createPGOrder() {
  console.log('Create PG Order');

  this.http.post(environment.apiUrl + 'PGManager/CreatePGOrder', {
    sessionid: '',
    feestopay: this.feestopay.toString(),
    newadmission: 'newadmission'
  })
  .subscribe((data: any) => {
      console.log(data);
      if (data.status === "Success") {
          if (this.payment_gateway_name === 'WorldLine') {
            var form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", data.url);    // "https://cgt.in.worldline.com/ipg/doMEPayRequest");
            form.setAttribute("target", "_self");
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("name", "merchantRequest");
            //hiddenField.setAttribute("value","3121044C3E93F5C15F2DC2F9991473AFA1539A3B05964ACFC16D72B65B10846CE3CF7908BA9CE85F18D3A1AABCB494DA23B7D9DAB07FD9559F5B57DA807D0E4E798017B3F4FA9EFED4C6B28C34F6662D719C41C650F9397B92CE8E5ED3DD38486D4F48BA66FAFCC24A6B8CD812E871C8B5AB29421E86C684F7BE6B2DAE5F7953" );
            hiddenField.setAttribute("value",data.res);
            hiddenField.setAttribute("type", "hidden");
            form.appendChild(hiddenField);

            var hiddenField2 = document.createElement("input");
            hiddenField2.setAttribute("name", "MID");
            // hiddenField2.setAttribute("value","WL0000000027698" );
            hiddenField2.setAttribute("value",data.mid);
            hiddenField2.setAttribute("type", "hidden");
            form.appendChild(hiddenField2);

            document.body.appendChild(form);
            form.submit();

          }
          else if (this.payment_gateway_name === 'RazorPay') {
            // call api to create order_id
            //  this.payWithRazor(data.res);
          }
      }
      else
      {
        alert("Required data of:\r\n" + data.errormessage);
      }
  }, 
  error => {
    alert("Check internet connection !!");
  });
}

selectfeetopay(val, srno, event: any) {
  //  let val = this.feepaymode;

  this.feestopay = 0;
  this.feepaymode = '';
  this.feesbeforetax = 0;

  this.sot.paymenttype = "";
  this.sot.totalfees = this.totalfees;
  this.sot.txnamount = 0.00;

  if (val === 'total') {
    //  this.seltotalfee - comes here when clicked and is value before clicked
    if (this.seltotalfee === false) {
      this.feesbeforetax = this.totalfees;

      this.feestopay = parseFloat((this.totalfees * (1.0 + this.gateway_charges)).toFixed(2));   // 2% Gateway Charges * 18% GST
      this.feepaymode = 'total';

      this.sot.paymenttype = "Total";
      //this.sot.txnamount = this.feesbeforetax.toFixed(2);
      this.sot.txnamount = this.feesbeforetax;
    }
  }
  else {
    this.minfeeselcnt = 0;
    
    this.minfees.forEach( (m:any) => {
      if (m.srno === srno) {
        if (m.selected === false) {
          this.feepaymode = 'installment';
          this.sot.paymenttype = "Installment";
          this.feestopay += m.minfee;
          ++this.minfeeselcnt;
        }
      }
      else if (m.selected === true) {
        this.feepaymode = 'installment';
        this.sot.paymenttype = "Installment";
        this.feestopay += m.minfee;
        ++this.minfeeselcnt;
      }
    });

    this.feesbeforetax = this.feestopay;
    this.feestopay = parseFloat((this.feestopay * (1.0 + this.gateway_charges)).toFixed(2));   // 2% Gateway Charges * 18% GST
    this.sot.txnamount = this.feesbeforetax;
  }

  console.log(event.target.checked);
}

payWithRazor(val) {
  console.log('Pay with Rzrpay');

  const options: any = {
    key: 'rzp_test_e2C1ysCO4dB18g',
    amount: this.feestopay * 100, // amount should be in paise format to display Rs 1255 without decimal point
    currency: 'INR',
    name: this.gp.institutedetails.instituteName, // company name or product name
    description: this.courseapplied.coursename + ' - Admission Fees',  // product description
    image: './assets/logo.png', // company logo or product image
    order_id: val, // order_id created by you in backend
    prefill: {
      name: this.stuadmform.surname + ' ' + this.stuadmform.name + ' ' + this.stuadmform.fname + ' ' + this.stuadmform.mname,
      email: this.stuadmform.emailid,
      phone: this.stuadmform.mobile,
      contact: this.stuadmform.mobile
    },
    modal: {
      // We should prevent closing of the form when esc key is pressed.
      escape: false,
    },
    notes: {
      // include notes if any
      //sessionid: this.gp.sessionid,
      //applicationno: this.stuadmform.applicationno,
      name: this.stuadmform.surname + ' ' + this.stuadmform.name + ' ' + this.stuadmform.fname + ' ' + this.stuadmform.mname,
    },
    theme: {
      color: '#0c238a'
    }
  };
  options.handler = ((response, error) => {
    debugger;
    options.response = response;
    console.log(response);
    console.log(options);
   
    this.studentDataService.OverRzpOrder(options.order_id,response)
    .subscribe((data: any) => {
        console.log(data);
        if (data.status === "Success") {
            // call api to create order_id
            this.payWithRazor(data.res);
        }
        else
        {
          alert("Required data of:\r\n" + data.errormessage);
        }
    }, 
    error => {
      alert("Check internet connection !!");
    });
    // call your backend api to verify payment signature & capture transaction
  });
  options.modal.ondismiss = (() => {
    // handle the case when user closes the form while transaction is in progress
    console.log('Transaction cancelled.');
  });
  const rzp = new this.winRef.nativeWindow.Razorpay(options);
  rzp.open();
}

payackphoto = "";
PayAckFile: File;

onFileSelPayAck(event) {
  this.PayAckFile = <File>event.target.files[0];

  if (this.PayAckFile.name.toLowerCase().endsWith(".png") 
        || this.PayAckFile.name.toLowerCase().endsWith(".jpg") || this.PayAckFile.name.toLowerCase().endsWith(".jpeg")){
  }
  else{
    alert("File should be JPG/PNG format only");
    return;
  }

  if (this.PayAckFile.size > 1024000){
    alert("File size exceeds 1 MB");
    return; 
  }

  var reader = new FileReader();
        reader.onload = (event: any) => {
            this.payackphoto = event.target.result;
        }
        reader.readAsDataURL(event.target.files[0]);
}

onClearPayAck() {
  this.PayAckFile = null;
}

//  Individual upload
onUploadPayAck(element: AdmDocsReq){
  if (element.docattached !== undefined && element.docattached !== null && element.docattached !== ''){
    if (element.fileselected !== undefined && element.fileselected !== null && element.fileselected.name !== undefined && element.fileselected.name !== '')
    {
      if (element.fileselected.name.toLowerCase().endsWith(".jpg") || element.fileselected.name.toLowerCase().endsWith(".jpeg")){

        if (element.fileselected.size > 1048576){
          element.statusmessage = "File size exceeds 1 MB";
        }
        else{
          element.statusmessage = "Uploading please wait...";
          element.isuploading = true;

          const fd = new FormData();
          //fd.append("sessionid", this.gp.sessionid);
          fd.append("documentid", element.documentid.toString());
          fd.append('image', element.fileselected, element.fileselected.name);
          //this.http.post(this.gp.basedataurl + 'StudAdmInfo/UploadAdmDocsReq', fd)
          this.studentDataService.UploadAdmDocsReq(fd)
              .subscribe((res: any) => {
                  console.log(res);
                  if (res.status === "Success")
                  {
                    element.uploadingover = true;
                    element.statusmessage = "Uploading successful";
                  }
                  else
                  {
                    element.statusmessage = res.errormessage;
                  }
              }, 
              error =>{
                element.statusmessage = "Unable to Upload";
          });
        }
      }
      else{
        element.statusmessage = "File should be JPG format only";
      }
    }
  }
}

SavePaymentData() {
  debugger;
  if (this.PayAckFile === undefined || this.PayAckFile === null || this.PayAckFile.name === undefined || this.PayAckFile.name === null || this.PayAckFile.name === '')
  {
    alert('Payment Acknowledgment Required !!');
    return;
  }

  if (this.PayAckFile.name.toLowerCase().endsWith(".jpg") || this.PayAckFile.name.toLowerCase().endsWith(".jpeg") || this.PayAckFile.name.toLowerCase().endsWith(".png")){

  }
  else{
    alert('File should be JPG/PNG format only');
    return;
  }

  if (this.PayAckFile.size > 1048576){
    alert('Sign file size more than 1MB');
    return;
  }

  this.sot.paymentmode = this.payment_method_type;

  const fd = new FormData();
  fd.append("applicationno", this.stuadmform.applicationno);
  fd.append("transactionno", this.sot.transactionno);
  fd.append("txndate", this.gp.dateuploadformat(this.sot.txndate));
  fd.append("paymentby", this.sot.paymentby);
  fd.append("paymentmode", this.sot.paymentmode);
  fd.append("paymenttype", this.sot.paymenttype);
  fd.append("txnamount", this.sot.txnamount.toFixed(2));
  fd.append("totalfees", this.sot.totalfees.toFixed(2));
  fd.append('image', this.PayAckFile, this.PayAckFile.name);
  
  this.studentDataService.SavePaymentData(fd)
  .subscribe((data: any) => {
      console.log(data);
      if (data.status === "Success") {
          alert('Payment data saved successfully');
          this.paymentsuccessful = true;
          this.sot.t_id = data.t_id;
          this.currentstage = data.stage;
      }
      else
      {
        alert("Required data of:\r\n" + data.errormessage);
      }
  }, 
  error => {
    alert("Check internet connection !!");
  });
}


AppllyforAdmission(){
  this.isApplDisable = false;
  this.studentDataService.AppllyforAdmission(this.stuadmform)
  .subscribe((a: any) => {
     
      if (a.status === 'Success') {
        this.stuadmform = a.res;
        this.stuadmfees = a.sfee;
        this.IsConfirmApplication = a.IsConfirmApplication;
      //  this.payform.mintopay = a.MinFeesTOPay;
        //this.payform.stuadmissionformid = a.res.stuadmissionformid;
        //this.payform.totalfees = a.sfee.totalfees;
        this.IsFillPayInfo = a.IsFillPayInfo;
        //this.validatePaymentForm();
      }
      if (a.status === 'Error') {
        this.isApplDisable = true;
       window.alert(a.errormessage)
      }
  });
}

GetStudentDeclaration(){
  this.mastreDataService.GetStudentDeclaration()
    .subscribe((a: any) => {
       
        if (a.status === 'Success') {
          this.StudentDeclaration.declarestudent = a.res;
        }
    });
 
}

GetParentDeclaration(){
  this.mastreDataService.GetStudentDeclaration()
  .subscribe((a: any) => {
     
      if (a.status === 'Success') {
        this.StudentDeclaration.declareguardian = a.res;
      }
  });
}

GetAntiRaggingDeclaration(){
  this.mastreDataService.GetStudentDeclaration()
  .subscribe((a: any) => {
     
      if (a.status === 'Success') {
        this.StudentDeclaration.antiraggingdeclaration = a.res;
      }
  });
}

}
export class DeclareDetail {
  declarestudent: string = "";
  declaretext: string = "";
  declareguardian: string = "";
  declaretext2: string = "";
  accept: boolean = false;
  antiraggingdeclaration: string="";
} 

export class paymentform {
  stuadmissionformid : Number = 0;
  txnamount: Number = 0;
  txndate: string = "";
  paymentby: string = "";
  paymentmode : string = "";
  transactionno: string = "";
  paymenttype: string = "";
  totalfees : Number = 0;
  mintopay : Number = 0;
} 


export class AdmDocsReq {
  documentid: number;
  documentdesc: string;
  docattached: string;
  fileselected: File;
  statusmessage: string;
  isuploading: boolean=false;
  uploadingover: boolean=false;
  fileevent: any;
}