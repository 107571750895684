import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/users/${id}`);
    }

    register(user: User) {
        console.log(user);

        const reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
        return this.http.post(`${environment.apiUrl}users/Register`, user, {headers: reqHeader});
    }

    forgotpassword(user: User) {
        console.log(user);

        const reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
        return this.http.post(`${environment.apiUrl}users/ForgotPassword`, user, {headers: reqHeader});
    }

    getUserInfo() {
        return this.http.get(`${environment.apiUrl}Account/GetUserLoginData`);
      }

}
