import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '@app/_models';
import { UserService, AuthenticationService, AlertService, StudentDataService, FileUploadService ,MasterDataService} from '@app/_services';
import { GlobalProvider } from '../../providers/global/global';
import { environment } from '@environments/environment';
import { ResultdataService } from '@app/_services/resultdata.service';
import { versions } from 'process';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {
  StudMarksData : any  = {};
  rowspanforGPA : Number;
  ResultExamtypenameDD=[];
  ShowResult: boolean = false;
  ResultExamType : string ='-1';
  feesDetails: any;
  feestopay = null;
  fees_balance_message = '';
  resultstatus = '';


  constructor(private gp : GlobalProvider,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private studentDataService: StudentDataService,
  private ResultdataService : ResultdataService,
  private mastreDataService :MasterDataService,
    private fileUploadService: FileUploadService) { }

  ngOnInit() {
      //this.getfeesDetails();

      this.GetExmTypeNameDD();
    //this.GetResultData();
  }

  private GetExmTypeNameDD() {
    this.mastreDataService.GetExmTypeNameDD()
    .subscribe((a: any) => {
        if (a.status === 'Success') {
            this.ResultExamtypenameDD = a.res;
            if (this.ResultExamtypenameDD != null && this.ResultExamtypenameDD.length > 1) {
                this.ResultExamType = this.ResultExamtypenameDD[1].Value;
                this.ResultExamtypenameDDonChange(this.ResultExamType);
            }
        }
    });
  }
  ResultExamtypenameDDonChange(value){
    this.ShowResult = false;
    this.feestopay = null;

      if(value == '-1'){
          this.ShowResult = false;}
        else{
            this.getfeesDetails();
            this.GetResultData(value);
        }  
      
  }

  getfeesDetails() {
    this.ShowResult = false;
    this.feestopay = null;

    this.studentDataService.GetPendingFeesDetails(this.ResultExamType).subscribe((a: any) => {
      if (a.status === "Success") {
        this.feestopay = a.res;
        this.fees_balance_message = a.fees_balance_message;
        if (this.feestopay !== null && this.feestopay === 0) {
            this.ShowResult = true;
        }
      }
      if (a.status === "Error") {
        window.alert(a.errormessage);
      }
    });
  }

  private GetResultData(ResultExamType) {
    this.ResultdataService.getResultData(ResultExamType)
    .subscribe((a: any) => {
   
        if (a.status === 'Success') {

            this.StudMarksData = a.res;
            this.GetrowspanforGPA(a.res);
            if (this.feestopay !== null && this.feestopay === 0) {
                this.ShowResult = true;
            }
            this.resultstatus = a.resultstatus;
        }
    });
  }

  getValue = function (mrk, colname) {
    
    var retVal = mrk[colname];
    if (retVal == undefined) {
        return "";
    }
    return retVal;
}
GetrowspanforGPA = function(data) {

    var rowcount=0;
   for(let i=1;i<=10;i++){
       var col ='ln'+ i.toString();
       if(this.getValue(data,col) != ''){
        rowcount++;
       }
   }
    this.rowspanforGPA = rowcount;
};
getMarks(mrk, colname) {
  var retVal = mrk[colname];
  if (retVal == undefined) {
      return 0;
  }
  if (retVal == "") {
      return 0;
  }
  if (retVal == "AB") {
      return 0;
  }
  if (retVal == "CC") {
      return 0;
  }
  return parseInt(retVal);
}
getTotal = function (mrk) {

  var total = this.getMarks(mrk, 's2pap1') + this.getMarks(mrk, 's2pap2') + this.getMarks(mrk, 's2pap3') + this.getMarks(mrk, 's2pap4') + this.getMarks(mrk, 's2pap5') +
      this.getMarks(mrk, 's2pap6') + this.getMarks(mrk, 's2pap7') + this.getMarks(mrk, 's2pap8') + this.getMarks(mrk, 's2pap9') + this.getMarks(mrk, 's2pap10');

  return total;
};
getTotalMark = function (mrk) {
 var total = this.getMarks(mrk, 's1') + this.getMarks(mrk, 's2') + this.getMarks(mrk, 's3') + this.getMarks(mrk, 's4') + this.getMarks(mrk, 's5') +
      this.getMarks(mrk, 's6') + this.getMarks(mrk, 's7') + this.getMarks(mrk, 's8') + this.getMarks(mrk, 's9') + this.getMarks(mrk, 's10');

  return total;
};
getrowspan (mrk) {
          
  var rowspan = 1;
  if (mrk != null && mrk != '' && mrk != 'undefined')
  {
      if (this.getValue(mrk, 'coursecode1') != "") {
          rowspan++;
      }
      if (this.getValue(mrk, 'coursecode2') != "") {
          rowspan++;
      }
      if (this.getValue(mrk, 'coursecode3') != "") {
          rowspan++;
      }
      if (this.getValue(mrk, 'coursecode4') != "") {
          rowspan++;
      }
      if (this.getValue(mrk, 'coursecode5') != "") {
          rowspan++;
      }
      if (this.getValue(mrk, 'coursecode6') != "") {
          rowspan++;
      }
      if (this.getValue(mrk, 'coursecode7') != "") {
          rowspan++;
      }
      if (this.getValue(mrk, 'coursecode8') != "") {
          rowspan++;
      }
      if (this.getValue(mrk, 'coursecode9') != "") {
          rowspan++;
      }
      if (this.getValue(mrk, 'coursecode10') != "") {
          rowspan++;
      }
  }
  return rowspan;
}
}
