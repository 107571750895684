import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { User } from '@app/_models';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public data :any = null;

    public subscription: any;

    constructor(private http: HttpClient,public router : Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this.data));
        this.currentUser = this.currentUserSubject.asObservable();
        this.subscription = this.currentUserSubject.subscribe(x => {

        });
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
      
        const data = 'username=' + username + '&password=' + password + '&grant_type=password';
        const reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
        // return this.http.post(this.rootUrl + 'token', data, { headers: reqHeader });

        // return this.http.post<any>(`${environment.apiUrl}/users/Authenticate`, { username, password }, { headers: reqHeader })
        return this.http.post<any>(`${environment.apiUrl}token`, data, { headers: reqHeader })
            .pipe(map(user => {
                console.log('Logged In');
                console.log(user);

                // login successful if there's a jwt token in the response
                if (user && user.access_token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    //localStorage.setItem('currentUser', JSON.stringify(user));
                   // this.currentUser. =JSON.stringify(user);
                  

                   
                    this.currentUser.subscribe(u =>  { 
                         user.data  =JSON.stringify(u);
                        });
                        this.data = JSON.stringify(user);
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        //localStorage.removeItem('currentUser');

        //this.currentUserSubject.unsubscribe();
        //this.currentUserSubject.next(null);

        this.subscription.unsubscribe();
        //this.currentUserSubject.next(null);
        this.router.navigate(['/login']);
    }

}
