import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalProvider } from '../../providers/global/global';
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ResultdataService {

  constructor(private http: HttpClient,private gp : GlobalProvider,
    ) {

      
     }


    getResultData(ResultExamType) {
      const data = { ResultExamType : ResultExamType } ;
    return this.http.post<any[]>(`${environment.apiUrl}Result/GetResultData`,data);
}

}
