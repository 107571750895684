export class User {
  id: number;

  username: string;
  password: string;
  firstName: string;
  lastName: string;
  fatherName: string;
  motherName: string;

  dateofBirth: Date;

  courseName: string;

  rollno: string;
  divisionName: string;
  data: any = null;
  token: string;
}
export class StuOnlineTransact {
  t_id: number;
  transactionno: string;
  txndate: Date;
  paymentby: string;
  paymentmode: string;
  paymenttype: string;
  txnamount: number;
  totalfees: number;
}
