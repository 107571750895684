import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "@environments/environment";

@Injectable({ providedIn: "root" })
export class StudentDataService {
  constructor(private http: HttpClient) {}

  getProfilePhotoPath() {
    return environment.photourl;
  }

  getAllAssignments() {
    return this.http.get<any[]>(
      `${environment.apiUrl}StudentInfo/GetAssignmentsForStudent`
    );
  }

  /// Get Assignment Attachments
  getAssignmentAttachment(id) {
    const data = { assignmentid: id };
    return this.http.post<any[]>(
      `${environment.apiUrl}StudentInfo/GetAssignmentAttachmentsOfStudent`,
      data
    );
  }

  /*  GetEmailId(id) {
        const data = {stuadmissionformid : id} ;
        return this.http.post<any[]>(`${environment.apiUrl}StudentInfo/GetEmailId`, data);
    } */
  getStudentInfo() {
    return this.http.get<any[]>(
      `${environment.apiUrl}StudentInfo/getStudentInfo`
    );
  }

  getCourseInfo() {
    return this.http.get<any[]>(
      `${environment.apiUrl}StudentInfo/getCourseInfo`
    );
  }

  GetStudExaminationInfo() {
    return this.http.get(`${environment.apiUrl}StudentInfo/GetStudExamInfo`);
  }

  getStudentAdmFormInfo() {
    return this.http.get<any[]>(
      `${environment.apiUrl}StudentInfo/GetStudentAdmFormInfo`
    );
  }
  GetStudentFeeData(cc_name, classno) {
    const data = { cc_name: cc_name, classno: classno };
    return this.http.post<any[]>(
      `${environment.apiUrl}StudentInfo/GetStudentFeesApplicable`,
      data
    );
  }
  GetLiabraryBooksList(term,selectedOption) {
    const data = {term: term,selectedOption:selectedOption };
    return this.http.post<any[]>(
      `${environment.apiUrl}StudentInfo/GetFillterwiseLiabraryBooksList`,
      data
    );
  }
  GetBooksData(selectedOption, item) {
    const data = { selectedOption: selectedOption, item: item };
    return this.http.post<any[]>(
      `${environment.apiUrl}StudentInfo/GetBooksDetails`,
      data
    );
  }
  getStudentAdmSubjects(formid) {
    const data = { formid: formid };
    return this.http.post<any[]>(
      `${environment.apiUrl}StudentInfo/GetStudentAdmSubjects`,
      data
    );
  }
  getStudentAdmDocsReq(formid) {
    const data = { formid: formid };
    return this.http.post<any[]>(
      `${environment.apiUrl}StudentInfo/GetStudentAdmDocsReq`,
      data
    );
  }
  getStudentExamInfo(formid) {
    const data = { formid: formid };
    return this.http.post<any[]>(
      `${environment.apiUrl}StudentInfo/GetStudentExamInfo`,
      data
    );
  }
  getStudentAdmFreeshipReq(formid) {
    const data = { formid: formid };
    return this.http.post<any[]>(
      `${environment.apiUrl}StudentInfo/GetStudentAdmFreeshipReq`,
      data
    );
  }

  GenerateAdmissionForm(newclassname) {
    const data = {newclassname: newclassname };

    return this.http.post<any[]>(
      `${environment.apiUrl}StudentInfo/GenerateAdmissionForm`,
      data
    );
  }

  ConfirmAdmissionForm(studentform) {
    const data = { studentform : studentform };

    return this.http.post<any[]>(
      `${environment.apiUrl}StudentInfo/ConfirmAdmissionForm`,
      data
    );
  }

  AppllyforAdmission(studentform) {
    const data = { studentform: studentform };
    return this.http.post<any[]>(
      `${environment.apiUrl}StudentInfo/ApplyForAdmission`,
      data
    );
  }

  /* SavePaymentForm(paymentform) {
        const data = {payform : paymentform} ;
        return this.http.post<any[]>(`${environment.apiUrl}StudentInfo/SavePaymentForm`, data);
    } */

  UploadAdmDocsReq(fd) {
    return this.http.post<any>(
      `${environment.apiUrl}StudentInfo/UploadAdmDocsReq`,
      fd
    );
  }
  SavePaymentData(fd) {
    return this.http.post<any>(
      `${environment.apiUrl}StudentInfo/SavePaymentData`,
      fd
    );
  }
  CreatePGOrder(feestopay) {
    const data = { feestopay: feestopay };
    return this.http.post<any>(
      `${environment.apiUrl}StudentInfo/GetStudentFeesApplicable`,
      data
    );
  }
  OverRzpOrder(order_id, response) {
    const data = { order_id: order_id, response: response };
    return this.http.post<any>(
      `${environment.apiUrl}PGManager/OverRzpOrder`,
      data
    );
  }

  GetFeesDetails() {
    return this.http.get<any[]>(`${environment.apiUrl}Fees/GetFeesDetails`);
  }

  GetPendingFeesDetails(resultexamtype) {
    return this.http.get<any[]>(`${environment.apiUrl}Fees/GetPendingFeesDetails?resultexamtype=${resultexamtype}`);
  }

  CheckCurrentAdmissionStatus() {
    return this.http.get<any[]>(`${environment.apiUrl}StudentInfo/CheckCurrentAdmissionStatus`);
  }

  GetStudOnlineTransactList() {
    return this.http.get<any[]>(
      `${environment.apiUrl}Fees/GetStudOnlineTransactList`
    );
  }
  GetStudReceiptList() {
    return this.http.get<any[]>(
      `${environment.apiUrl}Fees/GetStudReceiptList`
    );
  }
  GetStudBooksIssueReturnList() {
    return this.http.get<any[]>(
      `${environment.apiUrl}StudentInfo/getStudentLiabraryInfo`
    );
  }
  SaveFeesPaymentData(fd) {
    return this.http.post<any>(
      `${environment.apiUrl}Fees/SaveFeesPaymentData`,
      fd
    );
  }

  GetPhotoChangeRequest() {
    return this.http.get<any[]>(
      `${environment.apiUrl}StudentInfo/GetPhotoChangeRequest`
    );
  }

  UploadPhotoChangeReq(fd) {
    return this.http.post<any>(
      `${environment.apiUrl}StudentInfo/UploadPhotoChangeRequest`,
      fd
    );
  }

}
