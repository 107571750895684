import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { ForgotPasswordComponent } from './forgotpassword';
import {StudentprofileComponent} from './studentprofile/studentprofile.component';
import { AuthGuard } from './_guards';
import { ResultComponent } from './result/result.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdmissionComponent } from './admission/admission.component';
import {FeesComponent} from './fees/fees.component';
import { StudliabraryinfoComponent } from './liabrary/studliabraryinfo/studliabraryinfo.component';
import { SearchbooksindexComponent } from './searchbooksindex/searchbooksindex.component';
const appRoutes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard],
  children: [
    {
      path: '',
      component: DashboardComponent
    }, {
    path: 'Home',
    component: DashboardComponent
    },
    {
      path: 'studentprofile',
      component: StudentprofileComponent
  },
  {
    path: 'result',
    component: ResultComponent
},
{
  path: 'attachments',
  component: AttachmentsComponent
} ,
{
  path: 'admission',
  component: AdmissionComponent
} ,
{
  path: 'fees',
  component: FeesComponent
} ,
{
  path: 'liabrary',
  component: StudliabraryinfoComponent
},
{
  path: 'searchbooksindex',
  component: SearchbooksindexComponent
}
  ]
},
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

// @NgModule({
//   imports: [RouterModule.forRoot(appRoutes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }


export const routing = RouterModule.forRoot(appRoutes);
