import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, UserService, AuthenticationService, MasterDataService } from '@app/_services';

@Component({templateUrl: 'register.component.html'})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;

    courseList: string[];

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private alertService: AlertService,
        private masterdataService: MasterDataService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            fatherName: ['', Validators.required],
            motherName: ['', Validators.required],

            courseName: [''],
            rollNo: [''],
            divisionName: [''],
            dateofBirth: ['', [DateValidator]],

            username: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });

        this.masterdataService.getAllCourses()
            .subscribe(data => {
                this.courseList = data;
            },
            error => {
                this.alertService.error('Course Data - ' + error);
            });
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            console.log(this.registerForm.errors);
            return;
        }

        this.loading = true;
        this.userService.register(this.registerForm.value)
            .pipe(first())
            .subscribe(
                (data: any) => {
                    if (data.status === 'Success') {
                        alert('Registration Successful, \r\nClick OK to proceed to Login Page');
                        this.alertService.success('Registration successful', true);
                        this.router.navigate(['/login']);
                    } else {
                        this.alertService.error(data.errormessage);
                        this.loading = false;
                    }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}

export class DateValidator {
    static ptDate(control: FormControl): { [key: string]: any | null } {
        console.log('Date Validator ');
        console.log(control.value);
    // tslint:disable-next-line: max-line-length
        const ptDatePattern = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;

        if (!control.value.match(ptDatePattern)) {
            return { 'dateIsInvalid': true };
        }
        return null;
    }

    static usDate(control: FormControl): { [key: string]: any } {
        // tslint:disable-next-line: max-line-length
        const usDatePattern = /^02\/(?:[01]\d|2\d)\/(?:19|20)(?:0[048]|[13579][26]|[2468][048])|(?:0[13578]|10|12)\/(?:[0-2]\d|3[01])\/(?:19|20)\d{2}|(?:0[469]|11)\/(?:[0-2]\d|30)\/(?:19|20)\d{2}|02\/(?:[0-1]\d|2[0-8])\/(?:19|20)\d{2}$/;

        if (!control.value.match(usDatePattern)) {
            return { 'dateIsInvalid': true };
        }
        return null;
    }
}
