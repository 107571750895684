import { Component, OnInit } from '@angular/core';
import { GlobalProvider } from '../../providers/global/global';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private gp : GlobalProvider,) { }

  ngOnInit() {
   // this.gp.GetYearSession();
  }
}
