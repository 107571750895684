import { Component, OnInit } from "@angular/core";
import {
  UserService,
  AuthenticationService,
  AlertService,
  StudentDataService,
  FileUploadService,
  MasterDataService,
} from "@app/_services";
import { GlobalProvider } from "../../providers/global/global";
import { User, StuOnlineTransact } from "@app/_models";

import {environment} from "../../environments/environment";

import { from } from "rxjs";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: "app-fees",
  templateUrl: "./fees.component.html",
  styleUrls: ["./fees.component.css"],
})
export class FeesComponent implements OnInit {
  constructor(
    private studentDataService: StudentDataService,
    private mastreDataService: MasterDataService,
    public http: HttpClient,
    private gp: GlobalProvider //private winRef: WindowRefService
  ) {}
  feesDetails: FeesDetails = new FeesDetails();
  OnlineTranList: vStuOnlineTransact[];
  ReceptList: receiptmodel[];
  sot: StuOnlineTransact = new StuOnlineTransact();

  payackphoto = "";
  PayAckFile: File;

  IsConfirmApplication = true;

  feestopay = 0;

  // ModeOfPayment = "PaymentGateway";   //  Direct Transfer
  // payment_gateway_name = 'WorldLine';
  // payment_method_type = "paygate";
  // payment_gateway_enabled = true;

  ModeOfPayment = "DirectTransfer";
  payment_gateway_name = '';
  payment_method_type = "";
  payment_gateway_enabled = false;

  isAidedUnaided = "";

  ngOnInit() {
    this.feesDetails = new FeesDetails();
    this.sot = new StuOnlineTransact();
    this.sot.t_id = 0;
    this.sot.paymenttype = "Installment";
    this.sot.txnamount = 0.00;
    this.ReceptList = [];

    this.getfeesDetails();
    this.getStudOnlineTransactList();
    this.getStudReceiptList();

    var today = new Date();

    var todaydate = this.gp.dateuploadformat(today);
    //  document.getElementById("TransactionDate").setAttribute("max", todaydate);

    this.studentDataService.getCourseInfo().subscribe((x: any) => {
      if (x.status === "Success") {
        let ci = x.res;
        if (ci.aided !== undefined && ci.aided !== null) {
          if (ci.aided) {
            this.isAidedUnaided = "Aided";
          }
          else {
            this.isAidedUnaided = "Unaided";
          }
        }
      }
    });
  }

  changeModeOfPayment(newMode: string) {
    this.ModeOfPayment = newMode;
  }

  getfeesDetails() {
    this.studentDataService.GetFeesDetails().subscribe((a: any) => {
      if (a.status === "Success") {
        this.feesDetails = a.res;
        this.sot.txnamount = this.feesDetails.balrcptfee;
        this.feestopay = this.feesDetails.balrcptfee;
      }
      if (a.status === "Error") {
        window.alert(a.errormessage);
      }
    });
  }

  getStudOnlineTransactList() {
    this.studentDataService.GetStudOnlineTransactList().subscribe((a: any) => {
      if (a.status === "Success") {
        this.OnlineTranList = a.res;
      }
      if (a.status === "Error") {
        window.alert(a.errormessage);
      }
    });
  }
  getStudReceiptList() {
    this.studentDataService.GetStudReceiptList().subscribe((a: any) => {
      if (a.status === "Success") {
        debugger;
        this.ReceptList = a.res;
      }
      if (a.status === "Error") {
        window.alert(a.errormessage);
      }
    });
  }
  onFileSelPayAck(event) {
    this.PayAckFile = <File>event.target.files[0];

    if (
      this.PayAckFile.name.toLowerCase().endsWith(".jpg") ||
      this.PayAckFile.name.toLowerCase().endsWith(".jpeg")
    ) {
    } else {
      alert("File should be JPG/PNG format only");
      return;
    }

    if (this.PayAckFile.size > 1024000) {
      alert("File size exceeds 1 MB");
      return;
    }

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.payackphoto = event.target.result;
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  onClearPayAck() {
    this.PayAckFile = null;
  }

  change_payment_method() {
    //  alert(this.payment_method_type);
    this.sot.paymentmode = this.payment_method_type;
  }

  SavePaymentData() {
    if (
      this.PayAckFile === undefined ||
      this.PayAckFile === null ||
      this.PayAckFile.name === undefined ||
      this.PayAckFile.name === null ||
      this.PayAckFile.name === ""
    ) {
      alert("Payment Acknowledgment Required !!");
      return;
    }

    if (
      this.PayAckFile.name.toLowerCase().endsWith(".jpg") ||
      this.PayAckFile.name.toLowerCase().endsWith(".jpeg") ||
      this.PayAckFile.name.toLowerCase().endsWith(".png")
    ) {
    } else {
      alert("File should be JPG/PNG format only");
      return;
    }

    if (this.PayAckFile.size > 1048576) {
      alert("Sign file size more than 1MB");
      return;
    }
    if (this.payment_method_type == "") {
      alert("Please Select Payment method");
      return;
    }
    if (this.sot.txnamount == undefined ||
      this.sot.txnamount == null ||
      this.sot.txnamount == 0.00
    ) {
      alert("Please Select Payment method");
      return;
    }
    this.sot.paymentmode = this.payment_method_type;
    debugger;
    const fd = new FormData();
    // fd.append("sessionid", this.gp.sessionid);
    fd.append("transactionno", this.sot.transactionno);
    fd.append("txndate", this.gp.dateuploadformat(this.sot.txndate));
    fd.append("paymentby", this.sot.paymentby);
    fd.append("paymentmode", this.sot.paymentmode);
    fd.append("paymenttype", this.sot.paymenttype);
    fd.append("txnamount", this.sot.txnamount.toString());
    //fd.append("totalfees", this.sot.totalfees.toFixed(2));
    fd.append("image", this.PayAckFile, this.PayAckFile.name);

    this.studentDataService.SaveFeesPaymentData(fd).subscribe(
      (data: any) => {
        console.log(data);
        if (data.status === "Success") {
          alert("Payment data saved successfully");
          this.sot.t_id = data.t_id;
        } else {
          alert("Required data of:\r\n" + data.errormessage);
        }
      },
      (error) => {
        alert("Check internet connection !!");
      }
    );
  }

  public createPGOrder() {
    console.log('Create PG Order');

    this.http.post(environment.apiUrl + 'PGManager/CreatePGOrder', {
      sessionid: '',
      feestopay: this.feestopay.toString()
    })
    .subscribe((data: any) => {
        console.log(data);
        if (data.status === "Success") {
            if (this.payment_gateway_name === 'WorldLine') {
              var form = document.createElement("form");
              form.setAttribute("method", "post");
              form.setAttribute("action", data.url);    // "https://cgt.in.worldline.com/ipg/doMEPayRequest");
              form.setAttribute("target", "_self");
              var hiddenField = document.createElement("input");
              hiddenField.setAttribute("name", "merchantRequest");
              //hiddenField.setAttribute("value","3121044C3E93F5C15F2DC2F9991473AFA1539A3B05964ACFC16D72B65B10846CE3CF7908BA9CE85F18D3A1AABCB494DA23B7D9DAB07FD9559F5B57DA807D0E4E798017B3F4FA9EFED4C6B28C34F6662D719C41C650F9397B92CE8E5ED3DD38486D4F48BA66FAFCC24A6B8CD812E871C8B5AB29421E86C684F7BE6B2DAE5F7953" );
              hiddenField.setAttribute("value",data.res);
              hiddenField.setAttribute("type", "hidden");
              form.appendChild(hiddenField);

              var hiddenField2 = document.createElement("input");
              hiddenField2.setAttribute("name", "MID");
              // hiddenField2.setAttribute("value","WL0000000027698" );
              hiddenField2.setAttribute("value",data.mid);
              hiddenField2.setAttribute("type", "hidden");
              form.appendChild(hiddenField2);

              document.body.appendChild(form);
              form.submit();

            }
            else if (this.payment_gateway_name === 'RazorPay') {
              // call api to create order_id
              //  this.payWithRazor(data.res);
            }
        }
        else
        {
          alert("Required data of:\r\n" + data.errormessage);
        }
    }, 
    error => {
      alert("Check internet connection !!");
    });
  }

  selectfeetopay(val, srno, event: any) {
    // //  let val = this.feepaymode;

    // this.feestopay = 0;
    // this.feepaymode = '';
    // this.feesbeforetax = 0;

    // this.sot.paymenttype = "";
    // this.sot.totalfees = this.totalfees;
    // this.sot.txnamount = "0.00";

    // if (val === 'total') {
    //   //  this.seltotalfee - comes here when clicked and is value before clicked
    //   if (this.seltotalfee === false) {
    //     this.feesbeforetax = this.totalfees;

    //     this.feestopay = parseFloat((this.totalfees * (1.0 + this.gateway_charges)).toFixed(2));   // 2% Gateway Charges * 18% GST
    //     this.feepaymode = 'total';

    //     this.sot.paymenttype = "Total";
    //     this.sot.txnamount = this.feesbeforetax.toFixed(2);
    //   }
    // }
    // else {
    //   this.minfeeselcnt = 0;
      
    //   this.minfees.forEach( (m:any) => {
    //     if (m.srno === srno) {
    //       if (m.selected === false) {
    //         this.feepaymode = 'installment';
    //         this.sot.paymenttype = "Installment";
    //         this.feestopay += m.minfee;
    //         ++this.minfeeselcnt;
    //       }
    //     }
    //     else if (m.selected === true) {
    //       this.feepaymode = 'installment';
    //       this.sot.paymenttype = "Installment";
    //       this.feestopay += m.minfee;
    //       ++this.minfeeselcnt;
    //     }
    //   });

    //   this.feesbeforetax = this.feestopay;
    //   this.feestopay = parseFloat((this.feestopay * (1.0 + this.gateway_charges)).toFixed(2));   // 2% Gateway Charges * 18% GST
    //   this.sot.txnamount = this.feesbeforetax.toFixed(2);
    // }

    // console.log(event.target.checked);

  }


  payWithRazor(val) {
  /*
    console.log('Pay with Rzrpay');

    const options: any = {
      key: 'rzp_test_e2C1ysCO4dB18g',
      amount: this.feestopay * 100, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: this.gp.institutedetails.instituteName, // company name or product name
      description: this.gp.currentUser.courseName + ' - Admission Fees',  // product description
      image: './assets/logo.png', // company logo or product image
      order_id: val, // order_id created by you in backend
      prefill: {
        name: this.gp.currentUser.lastName + ' ' + this.gp.StudModel.name + ' ' + this.gp.StudModel.fname + ' ' + this.gp.StudModel.mname,
        email: '',  //this.gp.currentUser.rollno,
        phone: '',  //this.gp.currentUser.mobile,
        contact: '' //this.gp.currentUser.mobile
      },
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
        sessionid: this.gp.sessionid,
        applicationno: this.gp.currentUser.rollno,
        name: this.gp.currentUser.lastName + ' ' + this.gp.currentUser.firstName + ' ' + this.gp.currentUser.fatherName + ' ' + this.gp.currentUser.motherName,
      },
      theme: {
        color: '#0c238a'
      }
    };
    options.handler = ((response, error) => {
      debugger;
      options.response = response;
      console.log(response);
      console.log(options);
      this.http.post(this.gp.basedataurl + 'PGManager/OverRzpOrder', {
        sessionid: this.gp.sessionid,
        order_id: options.order_id,
        response : response
      })
      .subscribe((data: any) => {
          console.log(data);
          if (data.status === "Success") {
              // call api to create order_id
              this.payWithRazor(data.res);
          }
          else
          {
            alert("Required data of:\r\n" + data.errormessage);
          }
      }, 
      error => {
        alert("Check internet connection !!");
      });
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  */
  }


}
export class FeesDetails {
  classno: number;
  classname: string;
  cc_name: string;
  y_id: number;
  stuadmissionformid: number;
  applicationno: string;
  surname: string;
  name: string;
  fname: string;
  aided: boolean;
  stuadmissionchallanid: number;
  chaltotalfees: number;
  chaltotalfeestopay: number;
  stuadmissionfeeid: number;
  appltuitionfees: number;
  applotherfees: number;
  appltotalfees: number;
  balchalfee: number;
  balrcptfee: number;
  feeschemeid: number;
  feesapplicable: number;
  tuitionfees: number;
  otherfees: number;
  stuadmsnfeerecid: number;
  rec_feesapplicable: number;
  rec_feespaid: number;
}
export class vStuOnlineTransact {
  y_id: number;
  classname: string;
  classno: number;
  coursename: string;
  courseno: number;
  t_id: number = 0;
  stuadmissionformid: number;
  studentname: string;
  UniqueId: string;
  transactionno: string;
  txndate: Date;
  txnamount: number;
  paymentmod: string;
  paymenttype: string;
  paymentby: string;
  totalfees: number;
  phyfilename: string;
  chequeno: string;
  chequedate: Date;
  bankname: string;
  branchname: string;
  remarks: string;
}
export class receiptmodel {
  y_id: number;
  grstuno: number;
  applicationno: string;
  receiptno: string;
  receiptdate: Date;
  challanno: string;
  challandate: Date;
  feespaid: number;
  feesapplicable: number;
}
