import { Component, OnInit } from '@angular/core';

import { CommonModule } from '@angular/common';
import { UserService, 
  AuthenticationService, 
  AlertService, 
  StudentDataService, 
  FileUploadService } from '@app/_services';
//import { FormGroup, FormBuilder } from '@angular/forms';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { GlobalProvider } from '../../providers/global/global';
import { from } from "rxjs";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: 'app-searchbooksindex',
  templateUrl: './searchbooksindex.component.html',
  styleUrls: ['./searchbooksindex.component.css']
})
export class SearchbooksindexComponent implements OnInit {
  public isLoading: boolean;
  BooksMasterList: string[] = [];

  BooksList = [];
  
  keyword = "";
  selectedOption: string = 'Select';
  //public placeholder: string = 'Enter the Country Name';
  constructor(private studentDataService: StudentDataService,
    public http: HttpClient,
  ) { }

  ngOnInit(): void {
    // this.BooksMasterList=["acb","xyz"];
  }
  
  /**
   * API Data  (Filter on server)
   */
   onChangeSearch(term: string) {
    if(this.selectedOption == 'Select'){
      alert('Please select Filter Type...!');
      return;
    }
    if (term === undefined || term === null || term === '' || term.trim() === '' ) {
      return;
    }
    console.log('term', term);
    this.isLoading = true;
    this.studentDataService.GetLiabraryBooksList(term,this.selectedOption).subscribe((res: any) => {
      
      console.log('res', res);
      if (res.status === "Success") {
        this.BooksMasterList = res.bookslist;
        this.isLoading = false;
      }
      else {
        alert(res.errormessage);
      }
    }, (err) => {
      console.log('err', err);
      this.isLoading = false;
    });
  }
  
  selectEvent(item) {
    console.log('Selected item', item);
    this.studentDataService.GetBooksData(this.selectedOption,item)
    .subscribe((data: any) => {
      debugger;
        console.log(data);
        if (data !== undefined && data !== null) {
          if (data.status === "Success") {
            this.BooksList = data.booksdetails;
          }
        }
    }, 
    error => {
      alert("Check internet connection !!");
    });
  }
  focusedEventApi(e) {
    console.log('focused');
    // Fetch API data on Load
    this.onChangeSearch(null);
  }
  scrollToEndApi() {
    this.onChangeSearch('w');
  }
  clearEventApi() {
    console.log('cleared');
    this.BooksList =[];
  }
  openedEventApi() {
    console.log('opened');
  }
  closedEventApi() {
    console.log('closed');
    this.BooksList =[];
  }
  
  // GetStudentFeeData() {
  //   this.studentDataService.GetStudentFeeData(this.stuadmform.cc_name,this.courseApplied.classno)
  //  /*  this.http.post(this.gp.basedataurl + 'StudAdmInfo/GetStudentFeesApplicable', {
  //     sessionid: this.gp.sessionid,
  //     cc_name: this.StudModel.cc_name
  //   }) */
  //   .subscribe((data: any) => {
  //       console.log(data);
  //       if (data !== undefined && data !== null) {
  //         if (data.status === "Success") {
  //           this.totalfees = data.totalfees;

  //           this.minfees = data.feeinstallments;
  //           // this.feestopay = data.totalfees;
  //         }
  //       }
  //   }, 
  //   error => {
  //     alert("Check internet connection !!");
  //   });
  // }

  // openApiPanel(e): void {
  //   console.log('open');
  //   e.stopPropagation();
  //   this.ngAutocompleteApi.open();
  // }

  // closeApiPanel(e): void {
  //   console.log('close');
  //   e.stopPropagation();
  //   this.ngAutocompleteApi.close();
  // }

  // focusApiPanel(e): void {
  //   console.log('focus');
  //   e.stopPropagation();
  //   this.ngAutocompleteApi.focus();
  // }

  // keyword = 'name';
  // data = [
  //   {
  //     id: 1,
  //     name: 'India'
  //   },
  //    {
  //      id: 2,
  //      name: 'Usa'
  //    },
  //    {
  //      id: 3,
  //      name: 'England'
  //    },
  //    {
  //     id: 3,
  //     name: 'Yogesh'
  //   }
  // ];


  // selectEvent(item) {
  //   // do something with selected item
  // }

  // onChangeSearch(val: string) {
  //   // fetch remote data from here
  //   // And reassign the 'data' which is binded to 'data' property.
  // }
  
  // onFocused(e){
  //   // do something when input is focused
  // }

}
