import { Component, OnInit } from '@angular/core';
//import { GlobalProvider } from '../providers/global/global';
import {
  UserService,
  AuthenticationService,
  AlertService,
  StudentDataService,
} from "@app/_services";

//import { GlobalProvider } from "../../providers/global/global";
import { from } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-studliabraryinfo',
  templateUrl: './studliabraryinfo.component.html',
  styleUrls: ['./studliabraryinfo.component.css']
})
export class StudliabraryinfoComponent implements OnInit {

  constructor(
    private studentDataService: StudentDataService,
    public http: HttpClient,
    //private gp: GlobalProvider //private winRef: WindowRefService
  ) { }
  BooksIssueReturnList: issurreturnmodel[];
  ngOnInit(): void {
    this.BooksIssueReturnList = [];
    this.getStudentBooksIssueReturnList();

    var today = new Date();

    //var todaydate = this.gp.dateuploadformat(today);

  }
  dateformatter(params) {
    if (params !== undefined && params !== null) {
      // console.log('In date formatter - ' + params + ', ' + dateformat + ', ' + parseInt(params.value.substr(6)));

      const nowDate = new Date(parseInt(params.substr(6)));

      const strArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const strMonthNO = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      const d = nowDate.getDate();
      const m = strMonthNO[nowDate.getMonth()];
      const y = nowDate.getFullYear();
      var hours = nowDate.getHours();
      var minutes = nowDate.getMinutes();

      return (
        "" +
        (d <= 9 ? "0" + d : d) +
        "/" +
        m +
        "/" +
        y +
        " " +
        (hours < 10 ? "0" : "") +
        hours +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes
      );
    }
    return "";
  }

  getStudentBooksIssueReturnList() {
    this.studentDataService.GetStudBooksIssueReturnList().subscribe((a: any) => {
      if (a.status === "Success") {
        debugger;
        this.BooksIssueReturnList = a.res;
      }
      if (a.status === "Error") {
        window.alert(a.errormessage);
      }
    });
  }
  

}

export class issurreturnmodel {
  y_id: number;
  grstuno: number;
  ARtypewithprefix: string;
  ARTypeName: string;
  maintitle: string;
  //name: string;
  division: string;
  rollno: string;
  issuedate: Date;
  returndate: Date;
  expecteddate: Date;
  fineamtpayable: number;
  fineamtpaid: number;
}
