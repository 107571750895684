import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService ,AuthenticationService,AlertService ,MasterDataService} from './_services';
import { User } from './_models';
import { GlobalProvider, institutedetails } from 'src/providers/global/global';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    currentUser: User;
    title = 'edvu-college-student-web-clientapp-v1';

    constructor(
        public  gp : GlobalProvider,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private userService: UserService,
        private masterDataService :MasterDataService
    ) {
        
        console.log('In App');
     
        this.gp.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;
    
            // console.log(user);
    
            this.userService.getUserInfo()
            .subscribe((x: any) => {
                if (x.status === 'Success') {
                    // console.log('Got User Info');
                    // console.log(x);
                    this.gp.studentprofile = x.res;
                } else {
                    this.alertService.error(x.errormessage);
                }
            },
            e => {
                this.alertService.error(e);
                // this.loading = false;
            });
            
        }); 
       this.gp.getInstitutedetails();
    }

   
}
