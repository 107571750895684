import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { User } from '@app/_models';
import { UserService, AuthenticationService, AlertService, StudentDataService, FileUploadService,MasterDataService } from '@app/_services';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GlobalProvider } from  '../../providers/global/global';
import { Router } from '@angular/router';
@Component({ 
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
 

    studentprofile: any = {};
    assignments: [];
    selassign: any = {};
    assignattach: any;

    profileForm: FormGroup;
    error: string;
    filesize: 0;
    filename: '';
    fileUpload = {status: '', message: '', filePath: ''};
    uploading = false;

    constructor(
        public gp : GlobalProvider,
        public authenticationService: AuthenticationService,
        private masterDataService : MasterDataService,
        private userService: UserService,
        private alertService: AlertService,
        private router: Router,
        private studentDataService: StudentDataService,
        private fb: FormBuilder,
        private fileUploadService: FileUploadService
    ) {
        if (!this.authenticationService.currentUserValue) {
            this.router.navigate(['/login']);
        }
    }

    ngOnInit() {
       // this.loadAllAssignments();
       this.GetYearList();
       this.GetYearSession();
        this.profileForm = this.fb.group({
            name: [''],
            profile: ['']
        });

      //  this.selassign = null;
       // this.filesize = 0;
       // this.filename = '';
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.gp.destroySubscription();
    }
    ChangeYearSession(period){
        this.masterDataService.ChangeYearSession(period)
        .subscribe((aa: any) => {
           this.gp.selectedyear = aa.res 
        },
        err => console.log(err));
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['login']);
        return;
    }

    GetYearSession() {
        this.masterDataService.GetYearSession()
        .subscribe((a: any) => {
       
            if (a.status === 'Success') {         
                this.gp.selectedyear = a.res;            
            }
            else{
              this.authenticationService.logout();
            }
        });
      }
        GetYearList() {
          this.masterDataService.getYearList()
          .subscribe((a: any) => {
       
              if (a.status === 'Success') {         
                  this.gp.yearlist = a.res;            
              }
          });
        }
        GetInstitutedetails() {
       
          this.masterDataService.GetInstitutedetails()
          .subscribe((a: any) => {
            debugger;
              if (a.status === 'Success') {
                  this.gp.institutedetails = a.res;
              }
          });
        }
      
   /*  assignselection(a) {
        this.selassign = a;
        this.studentDataService.getAssignmentAttachment(a.assignmentid)
            .subscribe((aa: any) => {
                // console.log(aa);
                this.assignattach = aa.res;
            },
            err => console.log(err));
    } */

   /*  onSelectedFile(event) {
        if (event.target.files.length > 0) {
          const file = event.target.files[0];
          this.profileForm.get('profile').setValue(file);
          this.filesize = file.size;
          this.filename = file.name;
        }
    } */
/* 
    onSubmit() {
        this.error = '';

        const formData = new FormData();
        formData.append('assignmentid', this.selassign.assignmentid);
        formData.append('name', this.profileForm.get('name').value);
        formData.append('filename', this.filename);
        formData.append('filesize', this.filesize.toString());

        this.fileUploadService.attemptupload(this.selassign.assignmentid, 
                this.profileForm.get('name').value,
                this.filename, this.filesize.toString());

        formData.append('profile', this.profileForm.get('profile').value);

        this.uploading = true;

        this.fileUploadService.upload(formData).subscribe(
        res => {
            this.fileUpload = res;
            this.uploading = false;

            if (res.status === 'Success') {
                alert('File uploaded Successfully !!');

                this.assignattach.push(res.res);

            } else {
            this.error = res.errormessage;
            }
        },
        err => { this.error = err; this.uploading = false; }
        );
    }
 */
 /*    private loadAllAssignments() {
        this.studentDataService.getAllAssignments()
        .subscribe((a: any) => {
            if (a.status === 'Success') {
                this.assignments = a.res;
            }
        });
    } */

    dateformatter(params) {
        if (params !== undefined && params !== null) {
            // console.log('In date formatter - ' + params + ', ' + dateformat + ', ' + parseInt(params.value.substr(6)));

            const nowDate = new Date(parseInt(params.substr(6)));

            const strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const d = nowDate.getDate();
            const m = strArray[nowDate.getMonth()];
            const y = nowDate.getFullYear();
            return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
        }
        return '';
    }

   
    closeMenu() {
        
        var  w = window.innerWidth;
        var isMobile = /iPhone|iPad|iPod|BlackBerry|Opera Mini|IEMobile|Android/i.test(navigator.userAgent);
        if (isMobile || w < 800 ) {
          document.getElementById('navButton').click();
       }
    }
    standarddateformat(params) {
        if (params !== undefined && params !== null) {
          const nowDate = new Date(params);
          const strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          // const strArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
          const d = nowDate.getDate();
          const m = strArray[nowDate.getMonth()];
          const y = nowDate.getFullYear();
          return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
        }

        return '';

      }
}
