import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';


@Injectable({ providedIn: 'root' })
export class MasterDataService {
    constructor(private http: HttpClient) { }

    getAllCourses() {
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });

        return this.http.get<string[]>(`${environment.apiUrl}masters/GetAllCourses`, {headers: reqHeader});
    }
    ChangeYearSession(period){
        const data = {period : period} ;
        return this.http.post<any>(`${environment.apiUrl}masters/ChangeYearSession`, data);
    }
    GetYearSession(){
      
        return this.http.get(`${environment.apiUrl}masters/GetYearSession`);
    }
    getYearList(){
      
        return this.http.get(`${environment.apiUrl}masters/GetYearsList`);
    }
    GetInstitutedetails() {
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });

        return this.http.get<any>(`${environment.apiUrl}masters/GetInstituteDetails`, {headers: reqHeader});
    }

    GetStudentDeclaration(){
        return this.http.get(`${environment.apiUrl}masters/GetStudentDeclaration`);
    }
    GetParentDeclaration(){
        return this.http.get(`${environment.apiUrl}masters/GetParentDeclaration`);
    }
    GetAntiRaggingDeclaration(){
        return this.http.get(`${environment.apiUrl}masters/GetAntiRaggingDeclaration`);
    }
    GetGuardianDelcaration(){
        return this.http.get(`${environment.apiUrl}masters/GetGuardianDelcaration`);
    }
    // register(user: User) {
    //     return this.http.post(`${environment.apiUrl}/users/register`, user);
    // }
 
    GetExmTypeNameDD(){
        return this.http.get(`${environment.apiUrl}masters/GetExmTypeNameDD`);
    }

}
