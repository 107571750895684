//import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";

import { BehaviorSubject, Subscription, Observable } from "rxjs";

import { User } from "@app/_models";
import { environment } from "@environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  UserService,
  AuthenticationService,
  AlertService,
  StudentDataService,
  MasterDataService,
} from "@app/_services";
currentUser: User;
currentUserSubscription: Subscription;
/*
  Generated class for the GlobalProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class GlobalProvider {
  public currentUser: User;
  public currentUserSubscription: Subscription;
  public currentUserSubject: BehaviorSubject<User>;

  public photoURL: string = environment.photourl;

  studentprofile: any = {};
  public institutedetails: institutedetails = new institutedetails();
  yearlist = [];
  public selectedyear: any = {};
  constructor(
    private http: HttpClient,
    private masterDataService: MasterDataService
  ) {
    /* this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;

        // console.log(user);

        this.userService.getUserInfo()
        .subscribe((x: any) => {
            if (x.status === 'Success') {
                // console.log('Got User Info');
                // console.log(x);
                this.studentprofile = x.res;
            } else {
                this.alertService.error(x.errormessage);
            }
        },
        e => {
            this.alertService.error(e);
            // this.loading = false;
        });
        
    }); */
  }
  destroySubscription() {
    this.currentUserSubscription.unsubscribe();
  }
  getInstitutedetails() {
    this.masterDataService.GetInstitutedetails().subscribe((a: any) => {
      if (a.status === "Success") {
        this.institutedetails = new institutedetails();
        this.institutedetails = a.res;
      }
    });
  }
  dateformatter(params) {
    if (params !== undefined && params !== null) {
      // console.log('In date formatter - ' + params + ', ' + dateformat + ', ' + parseInt(params.value.substr(6)));

      const nowDate = new Date(parseInt(params.substr(6)));

      const strArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const strMonthNO = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      const d = nowDate.getDate();
      const m = strMonthNO[nowDate.getMonth()];
      const y = nowDate.getFullYear();
      var hours = nowDate.getHours();
      var minutes = nowDate.getMinutes();

      return (
        "" +
        (d <= 9 ? "0" + d : d) +
        "/" +
        m +
        "/" +
        y +
        " " +
        (hours < 10 ? "0" : "") +
        hours +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes
      );
    }
    return "";
  }
  dateuploadformat(params) {
    if (params !== undefined && params !== null) {
      var nowDate = new Date(params);

      var strArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var d = nowDate.getDate();
      var m = strArray[nowDate.getMonth()];
      var y = nowDate.getFullYear();
      return "" + y + "-" + m + "-" + (d <= 9 ? "0" + d : d);
    }

    return "";
  }
}
interface retData {
  status: string;
  res: any;
  errormessage: string;
}
export class CourseDetail {
  courseno: number;
  coursename: string;
  coursehead: string;
  coursesubhead: string;
  semesterno: string;
  isaided: boolean;
}

export class institutedetails {
  instituteId: number;
  instituteName: string;
  add1: string;
  add2: string;
  add3: string;
  headerlogo: string;
  printlogo: string;
  shortname: string;
  trustid: number;
  trustname: string;
  trustaddress: string;
  trustcontactno: string;
  universityid: number;
  active: boolean;
  isdeleted: boolean;
  createdon: Date;
  createdby: number;
}

export class AdmDocsReq {
  documentid: number;
  documentdesc: string;
  docattached: string;
  fileselected: File;
  statusmessage: string;
  isuploading: boolean = false;
  uploadingover: boolean = false;
  fileevent: any;
}
