import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {GlobalProvider } from  '../../providers/global/global';
// import 'rxjs/add/operator/do';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router,public gp : GlobalProvider) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log(req);
        console.log(req.headers);

        if (req.headers.get('No-Auth') === 'True') {
            return next.handle(req.clone());
        }

        if (this.gp.currentUser.token != null) {  // if (localStorage.getItem('userToken') != null) {
            const clonedreq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + this.gp.currentUser.token)
            });
            return next.handle(clonedreq)
                .pipe(tap(
                    err => {
                        if (err instanceof HttpErrorResponse) {
                            console.log(err);
                            console.log('req url :: ' + req.url);
                            if (err.status === 401) {
                            this.router.navigate(['user']);
                            }
                        }
                    }
                ));
        } else {
            this.router.navigateByUrl('/login');
        }
    }
}
