import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
//import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// used to create fake backend
import { fakeBackendProvider } from "./_helpers";

import { AppComponent } from "./app.component";
import { routing } from "./app.routing";
// import { AppRoutingModule } from './app-routing.module';
import { GlobalProvider } from "../providers/global/global";
import { AlertComponent } from "./_components";
import { JwtInterceptor, ErrorInterceptor } from "./_helpers";
import { HomeComponent } from "./home";
import { LoginComponent } from "./login";
import { RegisterComponent } from "./register";
import { AuthInterceptor } from "./_helpers/auth.interceptor";
import { ForgotPasswordComponent } from "./forgotpassword/forgotpassword.component";
import { StudentprofileComponent } from "./studentprofile/studentprofile.component";
import { ResultComponent } from "./result/result.component";
import { FeesComponent } from "./fees/fees.component";
import { AttachmentsComponent } from "./attachments/attachments.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { AdmissionComponent } from "./admission/admission.component";
import { StudliabraryinfoComponent } from './liabrary/studliabraryinfo/studliabraryinfo.component';
import { SearchbooksindexComponent } from './searchbooksindex/searchbooksindex.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    routing,
    AutocompleteLibModule
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    StudentprofileComponent,
    ResultComponent,
    FeesComponent,
    AttachmentsComponent,
    DashboardComponent,
    AdmissionComponent,
    StudliabraryinfoComponent,
    SearchbooksindexComponent,
  ],
  providers: [
    GlobalProvider,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    // fakeBackendProvider
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}
