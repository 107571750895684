import { Component, OnInit,OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '@app/_models';
import { UserService, AuthenticationService, AlertService, StudentDataService, FileUploadService } from '@app/_services';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GlobalProvider } from '../../providers/global/global';
import { environment } from '@environments/environment';
@Component({
  selector: 'app-studentprofile',
  templateUrl: './studentprofile.component.html',
  styleUrls: ['./studentprofile.component.css']
})

export class StudentprofileComponent implements OnInit, OnDestroy {
  currentUser: User;
  currentUserSubscription: Subscription;
studentinfo : any = {};
stumastinfo : any = {};
 public studentprofile: any = {};
 
 public StudentExamInfo: [];


  profileForm: FormGroup;
  error: string;
  filesize: 0;
  filename: '';
  fileUpload = {status: '', message: '', filePath: ''};
  uploading = false;

  PhotoReqFile: File;


  constructor(
      private authenticationService: AuthenticationService,
      private userService: UserService,
      private alertService: AlertService,
      public studentDataService: StudentDataService,
      private fb: FormBuilder,
      public gp : GlobalProvider,
      private fileUploadService: FileUploadService
  ) {
      // console.log('In Home');

      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;

          // console.log(user);

         
      });
  }

  ngOnInit() {
      this.GetStudentInfo();
      this.GetStudExaminationInfo();
      this.profileForm = this.fb.group({
          name: [''],
          profile: ['']
      });

  
      this.filesize = 0;
      this.filename = '';

      this.CheckPhotoChangeRequest();
    }

  ngOnDestroy() {
      // unsubscribe to ensure no memory leaks
      this.currentUserSubscription.unsubscribe();
  }

  private GetStudentInfo() {
    this.studentDataService.getStudentInfo()
    .subscribe((a: any) => {
       
        if (a.status === 'Success') {
            console.log("DAta");
            console.log(a);
         
            this.studentinfo = a.res;
            this.stumastinfo = a.stumastinfo;
        }
    });
}

private GetStudExaminationInfo() {
    this.studentDataService.GetStudExaminationInfo()
    .subscribe((a: any) => {
       
        if (a.status === 'Success') {
            console.log("Exam DAta");
            console.log(a);
         
            this.StudentExamInfo = a.res;
           
        }
    });
}
/* GetEmailid(stumastinfo){
    var email='';
    this.studentDataService.GetEmailId(stumastinfo.stuadmissionformid)
    .subscribe((a: any) => {
       
        if (a.status === 'Success') {
            email = a.res.emailid;
        }
    });
    return email;
} */
  dateformatter(params) {
      if (params !== undefined && params !== null) {
          // console.log('In date formatter - ' + params + ', ' + dateformat + ', ' + parseInt(params.value.substr(6)));

          const nowDate = new Date(parseInt(params.substr(6)));

          const strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          const d = nowDate.getDate();
          const m = strArray[nowDate.getMonth()];
          const y = nowDate.getFullYear();
          return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
      }
      return '';
  }

  standarddateformat(params) {
      if (params !== undefined && params !== null) {
        const nowDate = new Date(params);
        const strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        // const strArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        const d = nowDate.getDate();
        const m = strArray[nowDate.getMonth()];
        const y = nowDate.getFullYear();
        return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
      }

      return '';

    }

    isPhotoChange = false;

    CheckPhotoChangeRequest() {
        this.studentDataService.GetPhotoChangeRequest()
        .subscribe((a: any) => {
            if (a.status === 'Success') {
                this.isPhotoChange = true;
            }
        });
    }

    onFileSelPayAck(event) {
        this.PhotoReqFile = <File>event.target.files[0];
    
        if (
          this.PhotoReqFile.name.toLowerCase().endsWith(".jpg") ||
          this.PhotoReqFile.name.toLowerCase().endsWith(".jpeg")
        ) {
        } else {
          alert("File should be JPG/PNG format only");
          return;
        }
    
        if (this.PhotoReqFile.size > 1024000) {
          alert("File size exceeds 1 MB");
          return;
        }
    
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.filename = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    UploadPhotoChangeReq() {
        if (this.filename === '') {
            return;
        }

        const fd = new FormData();
        fd.append('image', this.PhotoReqFile, this.filename);

        this.studentDataService.UploadPhotoChangeReq(fd)
            .subscribe((res: any) => {
                console.log(res);
                if (res.status === "Success")
                {
                    this.isPhotoChange = false;
                    this.filename = '';
                }
                else
                {
                alert(res.errormessage);
                }
            }, 
            error =>{
            alert("Unable to Upload");
        });
    }
}
